<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="80" style="padding: 2rem">
        <el-col :span="12">
          <el-form >
            <el-form-item label="升级代理">
              加盟专区消费金额满
              <el-input-number v-model="form.up_agent_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="升级老板">
              直推
              <el-input-number v-model="form.up_boos"></el-input-number>
              个代理
            </el-form-item>
            <el-form-item label="升级老板留人策略">
              <el-radio-group v-model="form.up_boos_retain_person">
                <el-radio :label="0">只留帮扶用户</el-radio>
                <el-radio :label="1">留帮扶用户及帮扶用户邀请关系链</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="营销总监">
              佣金满
              <el-input-number v-model="form.up_chief_limit_fee"></el-input-number>
              元可提交申请
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="代理直推奖励">
              <el-input-number v-model="form.agent_invite_award_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="总监、老板直推奖励">
              <el-input-number v-model="form.invite_award_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="总监、老板见单奖励">
              <el-input-number v-model="form.jian_dan_award_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="总监、老板平级奖励">
              <el-input-number v-model="form.ping_ji_award_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="总监业绩奖励">
              <el-input-number v-model="form.ye_ji_award_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="总监、老板、代理每月免费领">
              <el-input-number v-model="form.free_num"></el-input-number>
              个免费专区产品
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="帮扶金比例">
              <el-input-number v-model="form.help_fee_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="提现手续费">
              <el-input-number v-model="form.withdrawal_fee"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="平级奖发放层级">
              <el-input-number v-model="form.ping_ji_layer"></el-input-number>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="会员别名">
              <el-input v-model="form.level_normal_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="代理别名">
              <el-input v-model="form.level_agent_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="老板别名">
              <el-input v-model="form.level_boos_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="总监别名">
              <el-input v-model="form.level_chief_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="免费区分享标题">
              <el-input v-model="form.free_share_title"></el-input>
            </el-form-item>
            <el-form-item label="免费区分享描述">
              <el-input v-model="form.free_share_desc"></el-input>
            </el-form-item>
            <el-form-item label="免费区分享图标">
              <y_upload_img v-model="form.free_share_pic"></y_upload_img>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="加盟区分享标题">
              <el-input v-model="form.upgrade_share_title"></el-input>
            </el-form-item>
            <el-form-item label="加盟区分享描述">
              <el-input v-model="form.upgrade_share_desc"></el-input>
            </el-form-item>
            <el-form-item label="加盟区分享图标">
              <y_upload_img v-model="form.upgrade_share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload/y_upload_img";
export default {
  name: "set",
  components: {y_upload_img},
  data() {
    return {
      list:[],
      form:{
        up_agent_fee:0,
        up_boos:0,
        up_chief_limit_fee:0,
        agent_invite_award_fee:0,
        invite_award_fee:0,
        jian_dan_award_fee:0,
        ping_ji_award_scale:0,
        ye_ji_award_fee:0,
        free_num:0,
        help_fee_scale:0,
        withdrawal_fee:0,

        level_normal_alias:"",
        level_agent_alias:"",
        level_boos_alias:"",
        level_chief_alias:"",

        free_share_title:"",
        free_share_desc:"",
        free_share_pic:"",
        upgrade_share_title:"",
        upgrade_share_desc:"",
        upgrade_share_pic:"",

        ping_ji_layer:0,

        up_boos_retain_person:0
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$api.addons.chain.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.addons.chain.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>